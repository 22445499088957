:root {
  --embed-flip-size: contain;
  --embed-background-color: #000000;
  --player-background-color: #000000;
}

.player-container {
  position: relative;
  width: 100%;
  .player-flip {
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    padding-top: calc(9 / 16 * 100%);
    width: 100%;
  }
  .player-wrapper {
    background-color: black;
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
  }
  .player-error {
    display: flex;
    align-items: center;
    padding: 10px;
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    background-color: black;
    color: #ffffff;
    text-align: left;
    .error {
      padding: 10px;
      .title {
        font-size: 24px;
      }
      .message {
        font-size: 16px;
      }
    }
  }

  &.embed {
    height: 100%;
    .player-flip {
      background-size: contain;
      background-size: var(--embed-flip-size);
      background-color: #000000;
      background-color: var(--embed-background-color);
      padding-top: 0;
      height: 100%;
    }
  }
}
