@import '~videojs-font/scss/icons';
.vjs-container {
  @import '~video.js/dist/video-js';
  overflow: hidden;
  height: 100%;
  > div {
    height: 100%;
  }

  video {
    background-color: var(--player-background-color);
  }

  .video-js.vjs-fluid {
    padding-top: calc(9 / 16 * 100%);
  }

  &:not(.subtitled) {
    .vjs-subs-caps-button {
      display: none !important;
    }
  }

  &.embed {
    .video-js.vjs-fluid {
      padding-top: 0;
    }
  }
}

.vjs-container .vjs-resolution-button .vjs-resolution-button-staticlabel:before {
  font-family: 'VideoJS';
  content: '\f110';
  font-size: 1.8em;
  line-height: 1.67;
}

.vjs-container .vjs-resolution-button ul.vjs-menu-content {
  width: 4em !important;
}

.vjs-container .vjs-resolution-button .vjs-menu {
  left: 0;
}
